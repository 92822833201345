import React from 'react'
import Layout from '../components/layout' 
import { graphql } from 'gatsby'
import blogStyles from '../styles/blog.module.scss'
import Head from '../components/head'

export const query = graphql`
query (
  $slug: String!
) {
  markdownRemark (
    fields: {
      slug: {
        eq: $slug
      }
    }
  ) {
    frontmatter {
      title
      date
    }
    html
  }
}
`

const Blog = (props) => {
    return (
        <Layout>
        <Head title={"Example page"} />
        <div 
          className={blogStyles.container}
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
        </Layout>
    )
}

export default Blog;